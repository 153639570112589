<template>
  <nav class="navbar navbar-expand-lg navbar-absolute"
      :class="{'bg-white': showMenu}">
       <!-- :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu}"> -->
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
          <button type="button"
                  class="navbar-toggler"
                  aria-label="Navbar toggle button"
                  @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
       
        <!-- " margin-left:33%"> -->
        <!-- <a class="navbar-brand" href="#">{{routeName}}</a> -->
      </div>
      <div class="text-center" style="flex-grow:1;">
        <img src="/img/logo_desafio.png" alt="" style="max-height: 40px" >
      </div>
      <!-- <div style="border: 1px solid red;" class="w-100 d-flex justify-content-center"> -->
        
      <!-- </div> -->
      
     
      <!-- <button class="navbar-toggler" type="button"
              @click="toggleMenu"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation-index"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button> -->

      <!-- <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <div class="search-bar input-group" @click="searchModalVisible = true">
              <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
                <i class="tim-icons icon-zoom-split"></i>
              </button>
            </div>
            <modal :show.sync="searchModalVisible"
                   class="modal-search"
                   id="searchModal"
                   :centered="false"
                   :show-close="true">
              <div slot="header">Buscar Número</div>
              <div slot="default" class="d-flex">
                  <input  v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="apenas números" style="border:1px solid #303F66;">
                  <a class="btn btn-sm"><i class="tim-icons icon-zoom-split" style="color:white; top: 20%;"></i></a>
              </div>
            </modal>
          </ul>
        </div>
      </collapse-transition> -->
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import Modal from '@/components/Modal';

  export default {
    components: {
      CollapseTransition,
      Modal
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        ADMIN_AUTH_USER: 'authuser_guruadmin'
      };
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      getUsuario() {
        try {
          let user = {}
          if (typeof localStorage.ADMIN_AUTH_USER !== "undefined") {
            user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          }
          if (user == null) {
            user = {}
          }
          return user
          // return {'name': 'foo', 'email': 'foo@bar.com'}
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      isLogado() {
        let user = this.getUsuario()
        return user != null && user['id'] != null
      },
      logout() {
        localStorage.ADMIN_AUTH_USER = null
        this.$router.push('/#/login')
      }
    }
  };
</script>
<style>
.search-bar {
  border: 1px solid gray;
}
.main-panel > .navbar {
  min-height: 63px;
}
</style>
