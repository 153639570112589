import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";


const Ranking = () => import(/* webpackChunkName: "dashboard" */"@/pages/Ranking.vue");
const Regulamento = () => import(/* webpackChunkName: "dashboard" */"@/pages/Regulamento.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/ranking",
    children: [
      {
        path: "ranking",
        name: "Ranking",
        component: Ranking
      },
      {
        path: "regulamento",
        name: "Regulamento",
        component: Regulamento
      },
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
